import dayjs, { Dayjs } from "dayjs";
import { CampaignStateCreated, CampaignStateInProgress } from "src/constants/campaigns";
import { ICampaign } from "redcircle-types";

const MIN_CAMPAIGN_TIMELINE_IN_DAYS = 30;

/**
 * Clones the original date and sets the clone to 3am EST of that same day.
 *
 * For Campaigns start and estimated end dates, we historically set the date to 3 am Eastern time
 * Unsure of the original reason why and keeping this for consistency
 * @param unixTimeStamp - The original date as unix time stamp
 * @returns Dayjs - the same date as 3am EST
 */
export const cloneDateAs3amEST = (date: Dayjs) => {
  return date.clone().utcOffset(-5, true).startOf("day").add(3, "h");
};

/**
 * Estimates the minimum start date for a campaign based on the current time
 * @returns Dayjs - the minimum start date for a campaign
 */
export const getCampaignMinimumStartDate = () => {
  return dayjs().utcOffset(-5, true).startOf("d").add(11, "day").add(3, "h");
};

export const getCampaignMinimumStartDateV2 = () => {
  return dayjs().utcOffset(-5, true).startOf("d").add(3, "day").add(3, "h");
};

/**
 * Estimates the campaign timeline based on the campaign start, end dates, and the current time
 * @param campaign
 * @returns [Dayjs, Dayjs] - the estimated start and end dates of the campaign
 */
export const initializeCampaignTimeline = (campaign: ICampaign) => {
  if (!campaign) return [];
  const campaignMinimumStartDate = campaign.isV2
    ? getCampaignMinimumStartDateV2()
    : getCampaignMinimumStartDate();

  // initialize the start and end dates based on the campaign type
  const campaignStartsAt = campaign.isV2 ? campaign.startsAtV2 : campaign.startsAt;
  let startsAt =
    campaignStartsAt && campaignStartsAt > 0
      ? dayjs.unix(campaignStartsAt)
      : campaignMinimumStartDate;

  const campaignEndsAt = campaign.isV2 ? campaign.endsAt : campaign.estimatedEndsAt;
  let endsAt =
    campaignEndsAt && campaignEndsAt > 0
      ? dayjs.unix(campaignEndsAt)
      : dayjs(startsAt).add(MIN_CAMPAIGN_TIMELINE_IN_DAYS, "d");

  // adjust invalid start and end dates by setting to default
  if (startsAt.isBefore(campaignMinimumStartDate)) {
    const diff = endsAt.diff(startsAt, "h");
    startsAt = campaignMinimumStartDate;
    endsAt = dayjs(startsAt).add(diff, "h");
  }
  if (endsAt.isBefore(startsAt) || endsAt.isSame(startsAt)) {
    endsAt = dayjs(startsAt).add(MIN_CAMPAIGN_TIMELINE_IN_DAYS, "d");
  }

  return [startsAt, endsAt];
};

const RESPONSE_DUE_DAYS_AFTER_SEND = 5;
const AUDIO_DUE_DAYS_BEFORE_START = 3;
/**
 * Estimates the campaign deadlines based on start dates and current time
 * @returns {startsAt: Dayjs, responseDue: Dayjs, audioDue: Dayjs} - the estimated deadlines for the campaign
 */
export const initializeCampaignDeadlines = (campaign: ICampaign) => {
  if (!campaign) return {};
  const campaignStartsAt = campaign.isV2 ? campaign.startsAtV2 : campaign.startsAt;
  const campaignMinimumStartDate = campaign.isV2
    ? getCampaignMinimumStartDateV2()
    : getCampaignMinimumStartDate();

  let startsAt =
    campaignStartsAt && campaignStartsAt > 0
      ? dayjs.unix(campaignStartsAt)
      : campaignMinimumStartDate;

  // adjust the start date if it's before the minimum start date
  if (startsAt.isBefore(campaignMinimumStartDate)) {
    startsAt = campaignMinimumStartDate;
  }

  const campaignAssignAudioDeadline = campaign.assignAudioDeadline;
  let defaultAssignAudioDeadline = startsAt
    .subtract(AUDIO_DUE_DAYS_BEFORE_START, "days")
    .startOf("day"); // 3 days before start
  if (defaultAssignAudioDeadline.isBefore(dayjs())) {
    defaultAssignAudioDeadline = dayjs().add(1, "day").startOf("day");
  }

  const campaignResponseDeadline = campaign.responseDeadline;
  let defaultResponseDeadline = dayjs().add(RESPONSE_DUE_DAYS_AFTER_SEND, "days").startOf("day"); // 5 days after today
  if (
    defaultResponseDeadline.isAfter(defaultAssignAudioDeadline) ||
    defaultResponseDeadline.isSame(defaultAssignAudioDeadline, "day")
  ) {
    defaultResponseDeadline = defaultAssignAudioDeadline.subtract(1, "day").startOf("day");
  }
  if (defaultResponseDeadline.isBefore(dayjs())) {
    defaultResponseDeadline = dayjs().add(1, "day").startOf("day");
    defaultAssignAudioDeadline = dayjs().add(2, "day").startOf("day");
  }

  return {
    startsAt,
    responseDeadline:
      campaignResponseDeadline && campaignResponseDeadline > 0
        ? dayjs.unix(campaignResponseDeadline)
        : cloneDateAs3amEST(defaultResponseDeadline),
    assignAudioDeadline:
      campaignAssignAudioDeadline && campaignAssignAudioDeadline > 0
        ? dayjs.unix(campaignAssignAudioDeadline)
        : cloneDateAs3amEST(defaultAssignAudioDeadline),
  };
};

const TOO_SOON_DAYS = 9;
const CAMPAIGN_DURATION_TOO_SHORT_DAYS = 3;
const RESPONSE_DEADLINE_TOO_SOON_DAYS = 4;
export const getCampaignTimelineWarnings = ({
  campaign,
  startsAt,
  endsAt,
  responseDeadline,
  assignAudioDeadline,
}: {
  campaign?: ICampaign;
  startsAt?: Dayjs;
  endsAt?: Dayjs;
  responseDeadline?: Dayjs;
  assignAudioDeadline?: Dayjs;
}) => {
  const warnings = [];

  if (
    campaign?.state === CampaignStateCreated &&
    startsAt &&
    startsAt.diff(dayjs(), "day") < TOO_SOON_DAYS
  ) {
    warnings.push({
      type: "warning",
      message: `Campaign start date is less than ${TOO_SOON_DAYS + 1} days away. Consider a later start date.`,
    });
  }

  if (
    campaign?.pacing &&
    startsAt &&
    endsAt &&
    endsAt.diff(startsAt, "day") < CAMPAIGN_DURATION_TOO_SHORT_DAYS
  ) {
    warnings.push({
      type: "warning",
      message: `Campaign duration is very short. Consider a longer campaign.`,
    });
  }

  if (
    campaign?.state === CampaignStateCreated &&
    responseDeadline &&
    responseDeadline.diff(dayjs(), "day") < RESPONSE_DEADLINE_TOO_SOON_DAYS
  ) {
    warnings.push({
      type: "warning",
      message: `Response deadline is very soon. Consider giving podcasters more time to respond.`,
    });
  }

  if (campaign && campaign.state === CampaignStateInProgress) {
    warnings.push({
      type: "warning",
      message:
        "This campaign is already in progress - any changes to flighting options will also be applied to all podcasts that have already been sent. This will not affect any existing overrides.",
    });
  }

  return warnings;
};
