import { Form, Timeline } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { pluralize } from "redcircle-lib";
import { CampaignStateCompleted, CampaignStateInProgress } from "src/constants/campaigns";
import SchedulerDatePicker from "../campaign_scheduler_modal/scheduler_datepicker";

export default function FlightingDateForm({
  type = "campaign",
  isV2 = true,
  isPostSend = false,
}: {
  type?: "campaign" | "campaignItem";
  isV2?: boolean;
  isPostSend?: boolean;
}) {
  const form = Form.useFormInstance();
  const formValues = Form.useWatch([], { form, preserve: true }) || {};
  const pacing = formValues.pacing as boolean;
  const timeline = formValues.timeline as dayjs.Dayjs[];
  const deadlines = formValues.deadlines;
  const typeToFriendly = type === "campaign" ? "Campaign" : "Campaign Item";

  const campaignIsModifiable =
    type === "campaign" &&
    (formValues.state === CampaignStateInProgress || formValues.state === CampaignStateCompleted);

  const items = [
    {
      children: (
        <span>
          Podcaster response due: <strong>{deadlines?.responseDeadline?.format("M/D/YYYY")}</strong>
        </span>
      ),
    },
    {
      children: (
        <span>
          Audio due: <strong>{deadlines?.assignAudioDeadline?.format("M/D/YYYY")}</strong>
        </span>
      ),
    },
    {
      children: (
        <span>
          {typeToFriendly} will start: <strong>{timeline?.[0]?.format("M/D/YYYY")}</strong>
        </span>
      ),
    },
  ];

  if (timeline && timeline[1] && pacing) {
    const diff = timeline[1].diff(timeline[0], "day");
    items.push({
      children: (
        <span>
          {typeToFriendly} will run for:{" "}
          <strong>
            {diff} {pluralize(diff, "day")}
          </strong>
        </span>
      ),
    });
    items.push({
      children: (
        <span>
          {typeToFriendly} will end: <strong>{timeline[1].format("M/D/YYYY")}</strong>
        </span>
      ),
    });
  }

  const handleDeadlineChange = (value: {
    assignAudioDeadline?: Dayjs;
    responseDeadline?: Dayjs;
  }) => {
    form.setFieldValue("deadlines", { ...deadlines, ...value });
  };

  return (
    <div>
      <span className="redcircle-form-label">Timeline</span>
      <div className="flex-row-container align-center">
        <Form.Item name="timeline">
          <SchedulerDatePicker
            isRange={pacing}
            deadlineValue={deadlines}
            onDeadlineChange={handleDeadlineChange}
            isV2={isV2}
            isPostSend={campaignIsModifiable || isPostSend}
          />
        </Form.Item>
      </div>
      <Timeline items={items} className="m-txs" />
    </div>
  );
}
