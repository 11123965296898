import { DatePicker, DatePickerProps, Divider, Form, Input, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { AiFillFolder, AiFillStar, AiOutlineClose } from "react-icons/ai";
import { Button, Modal } from "redcircle-ui";
import { showSuccess, showWarning } from "src/actions/app";
import { copyCampaign, getCampaignsForUser } from "src/action_managers/campaigns";
import {
  cloneDateAs3amEST,
  getCampaignMinimumStartDate,
} from "src/components/modals/campaign_scheduler_modal/campaign_scheduler_utils";
import { useGetCampaign } from "src/hooks/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { ICampaign, ICampaignTag, isCampaign } from "redcircle-types";
import { getCampaignField } from "src/lib/campaign";

export const NewCampaignTagModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!open) setValue("");
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} size="xs">
      <Modal.Title>Create New Folder</Modal.Title>
      <Modal.Body>
        <Input
          type="text"
          size="large"
          placeholder="Enter folder name"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton disabled={!value} onClick={() => onSubmit(value)} className="m-la">
          Create
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const EditCampaignTagModal = ({
  open,
  tag,
  onClose,
  onSubmit,
}: {
  open: boolean;
  tag?: ICampaignTag;
  onClose: () => void;
  onSubmit: (value: string, tag: ICampaignTag) => void;
}) => {
  const [value, setValue] = useState(tag?.title || "");

  useEffect(() => {
    if (open && tag) setValue(tag.title);
  }, [tag, open]);

  return (
    <Modal open={open} onClose={onClose} size="xs">
      <Modal.Title>Rename Folder</Modal.Title>
      <Modal.Body>
        <Input
          type="text"
          size="large"
          placeholder="Enter folder name"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton
          disabled={!value}
          onClick={() => {
            if (tag) onSubmit(value, tag);
          }}
          className="m-la">
          Save
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const DeleteCampaignTagModal = ({
  open,
  tag,
  onClose,
  onSubmit,
}: {
  open: boolean;
  tag?: ICampaignTag;
  onClose: () => void;
  onSubmit: (tag: ICampaignTag) => void;
}) => {
  return (
    <Modal open={open} onClose={onClose} size="xs">
      <Modal.Title>Delete {tag?.title}</Modal.Title>
      <Modal.Body>
        Are you sure you want to delete {tag?.title} folder? This will move all visible and archived
        campaigns in the folder to All Campaigns.
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton
          onClick={() => {
            if (tag) onSubmit(tag);
          }}
          className="m-la">
          Delete
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const MoveToFolderModal = ({
  open,
  item,
  tags = [],
  onClose,
  onSubmit,
}: {
  open: boolean;
  item?: ICampaignTag & ICampaign;
  tags?: ICampaignTag[];
  onClose: () => void;
  onSubmit: (value: string | null) => void;
}) => {
  const [selectedFolderUUID, setSelectedFolderUUID] = useState<string | null | undefined>();

  useEffect(() => {
    if (!open) setSelectedFolderUUID(undefined);
  }, [open]);

  const handleSubmit = () => {
    if (selectedFolderUUID || selectedFolderUUID === null) onSubmit(selectedFolderUUID);
    onClose();
  };

  const options = [
    {
      value: null,
      label: (
        <span className="flex-row-container align-center">
          <AiFillStar className="m-rxxs" /> All Campaigns
        </span>
      ),
      disabled: !item?.parentCampaignTagUUIDs || item.parentCampaignTagUUIDs === null,
    },
    ...tags.map((tag) => ({
      value: tag.uuid,
      label: (
        <span className="flex-row-container align-center">
          <AiFillFolder className="m-rxxs" /> {tag.title}
        </span>
      ),
      disabled: item?.parentCampaignTagUUIDs && item.parentCampaignTagUUIDs.includes(tag.uuid),
    })),
  ];

  return (
    <Modal open={open} onClose={onClose} size="xs">
      <Modal.Title>Move {item && item.name}</Modal.Title>
      <Modal.Body>
        <Select
          size="large"
          onSelect={(value) =>
            setSelectedFolderUUID(typeof value === "string" ? value.toString() : value)
          }
          placeholder="Select a Folder..."
          className="width-100"
          value={selectedFolderUUID}
          options={options}
        />
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton
          disabled={selectedFolderUUID === undefined}
          onClick={handleSubmit}
          className="m-la">
          Move to Folder
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const ArchiveCampaignModal = ({
  open,
  campaign,
  onClose,
  onSubmit,
}: {
  open: boolean;
  campaign?: ICampaign;
  onClose: () => void;
  onSubmit: (campaign: ICampaign) => void;
}) => {
  return (
    <Modal open={open} onClose={onClose} size="xs">
      <Modal.Title>Archive '{campaign?.name}'</Modal.Title>
      <Modal.Body>Are you sure you want to archive '{campaign?.name}' campaign?</Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton
          onClick={() => {
            if (campaign) onSubmit(campaign);
          }}
          className="m-la">
          Archive
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

const CAMPAIGN_COPY_MAX_COPIES = 20;

export default function CampaignCopyModal({
  closeRoute,
  params,
}: {
  closeRoute: () => void;
  params: any;
}) {
  const { campaignUUID } = params;
  const [form] = Form.useForm();
  const campaign = useGetCampaign(campaignUUID);
  const { user } = useSelectorTS((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatchTS();

  const handleSubmit = async () => {
    if (!isCampaign(campaign)) {
      dispatch(
        showWarning("Campaign information has not loaded, please try again in a few seconds.")
      );
      return;
    }

    const { campaigns } = form.getFieldsValue() as {
      campaigns: { name: string; startsAt: Dayjs }[];
    };

    if (!campaigns.every((campaign) => campaign.startsAt?.isValid())) {
      dispatch(showWarning("Must select a valid campaign start date."));
      return;
    }
    const campaignRequestBodies = campaigns.map(
      ({ name, startsAt }: { name: string; startsAt: Dayjs }, index: number) => {
        return {
          name: name || generateDefaultName(index),
          campaignUUID: campaign?.uuid,
          startsAt: cloneDateAs3amEST(startsAt)?.unix(),
        };
      }
    );

    const promises = campaignRequestBodies.map((body) => {
      return dispatch(copyCampaign(campaignUUID, body));
    });

    setIsSubmitting(true);
    const res = await Promise.all(promises);
    const success = res.every((r) => r.status === 200);
    if (success) {
      dispatch(getCampaignsForUser(user.uuid));
      dispatch(showSuccess(`Campaign copied ${campaignRequestBodies.length} times`));
      closeRoute();
    }
    setIsSubmitting(false);
  };

  const handleDisabledDate: DatePickerProps["disabledDate"] = (date) => {
    if (date?.isValid() && isCampaign(campaign)) {
      return date?.unix() < getCampaignMinimumStartDate()?.unix();
    }
    return false;
  };

  const generateDefaultName = (index: number) => {
    if (!campaign) return "";
    return index ? `${campaign.name} (copy ${index + 1})` : `${campaign.name} (copy 1)`;
  };

  // generates new date based on last date in campaigns array
  const generateDefaultDate = () => {
    const formValues = form.getFieldsValue();
    const { campaigns } = formValues;
    if (!campaigns || campaigns.length === 0) return null;

    const lastCampaign = campaigns[campaigns.length - 1];
    const lastCampaignDate = getCampaignField("startsAt", { campaign: lastCampaign });
    if (!lastCampaignDate) return null;

    return dayjs(lastCampaignDate).add(1, "month");
  };

  return (
    <Modal open={true} onClose={closeRoute} size="xs" onSubmit={handleSubmit}>
      {campaign && (
        <>
          <Modal.Title>Copy Campaign: {campaign.name}</Modal.Title>
          <Modal.Body>
            <Form form={form} initialValues={{ campaigns: [{ name: generateDefaultName(0) }] }}>
              <Form.List name="campaigns">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <React.Fragment key={key}>
                        <div className="flex-row-container align-center justify-space-between">
                          <label
                            className="redcircle-form-label fs-11"
                            htmlFor={`campaigns_${index}_name`}>
                            Campaign Name:
                          </label>
                          {fields.length > 1 && (
                            <Button type="link" className="p-a0" onClick={() => remove(index)}>
                              <AiOutlineClose />
                            </Button>
                          )}
                        </div>
                        <Form.Item {...restField} name={[name, "name"]}>
                          <Input placeholder={generateDefaultName(index)} />
                        </Form.Item>
                        <label
                          className="redcircle-form-label fs-11"
                          htmlFor={`campaigns_${index}_startsAt`}>
                          Start Date:
                        </label>
                        <Form.Item {...restField} name={[name, "startsAt"]}>
                          <DatePicker disabledDate={handleDisabledDate} />
                        </Form.Item>
                        <Divider />
                      </React.Fragment>
                    ))}
                    {fields.length < CAMPAIGN_COPY_MAX_COPIES && (
                      <Form.Item>
                        <Button
                          onClick={() =>
                            add({
                              name: generateDefaultName(fields.length),
                              startsAt: generateDefaultDate(),
                            })
                          }>
                          + Add Another
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Form>
          </Modal.Body>

          <Modal.Footer noBorder>
            <Modal.CloseButton>Close</Modal.CloseButton>
            <Modal.SubmitButton isLoading={isSubmitting}>Copy</Modal.SubmitButton>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
