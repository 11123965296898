import { Alert, Checkbox, Divider, Form, Tabs, TabsProps, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { pluralize } from "redcircle-lib";
import { Button, MultiModal } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import { overrideCampaignItems, updateCampaignItems } from "src/action_managers/campaigns";
import { OverridesText } from "src/components/pages/campaigns/override_popover";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import {
  CAMPAIGN_OVERRIDE_FIELDS,
  diffFieldsFromCampaign,
  diffRequestFromCampaignItem,
  getCampaignItemOverrides,
  overrideValueToFriendly,
  transformFormFieldsToOverrideRequest,
} from "src/lib/overrides";
import { ICampaign, ICampaignTargetingOptions } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShowsReduxState } from "src/reducers/public_show";
import FlightingDateForm from "../campaign_editor_modal/flighting_date_form";
import FlightingOptionsForm from "../campaign_editor_modal/flighting_options_form";
import {
  initializeCampaignDeadlines,
  initializeCampaignTimeline,
} from "../campaign_scheduler_modal/campaign_scheduler_utils";
import ModifyPricingForm from "./modify_pricing_form";
import ReforecastAlert from "./reforecast_alert";

export default function ModifyCampaignItemModal({
  type,
  open,
  campaign,
  campaignItemUUIDs = [],
  onClose,
}: {
  type: "override" | "modify";
  open: boolean;
  campaign?: ICampaign;
  campaignItemUUIDs?: string[];
  onClose: () => void;
}) {
  const dispatch = useDispatchTS();
  const [form] = Form.useForm();
  const fields = Form.useWatch([], { form, preserve: true });
  const fieldChanges = diffFieldsFromCampaign(fields, campaign);

  const [currentTab, setCurrentTab] = useState("dates");
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { campaignItems: allCampaignItems } = useSelectorTS((state) => state.campaignItems);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const campaignItems = useMemo(
    () =>
      campaignItemUUIDs.reduce(
        (acc, uuid) => {
          acc[uuid] = allCampaignItems[uuid];
          return acc;
        },
        {} as Record<string, ICampaignItem>
      ),
    [allCampaignItems, campaignItemUUIDs]
  );
  const isEditingOneItem = campaignItemUUIDs.length === 1;
  const oneItemOverrides = isEditingOneItem
    ? getCampaignItemOverrides(Object.values(campaignItems)[0])
    : {};

  // set initial values
  useEffect(() => {
    if (open) {
      if (!campaignItems || !campaign || !Object.keys(campaignItems).length) return;
      form.resetFields();
      form.setFieldsValue(
        initializeFormFields({ campaign, campaignItems, shows: publicShows, type })
      );
    } else {
      setCurrentTab("dates");
      setCurrentPage(0);
    }
  }, [open, campaign, campaignItems]);

  // modify end date on pacing change
  const pacing = Form.useWatch("pacing", { form, preserve: true }) as boolean;
  useEffect(() => {
    const timeline = form.getFieldValue("timeline");
    if (pacing && campaign && (!timeline[1] || timeline[1] < timeline[0])) {
      const initializedTimeline = initializeCampaignTimeline(campaign);
      form.setFieldsValue({ timeline: [timeline[0], initializedTimeline[1]] });
    }
  }, [pacing]);

  const handleSubmit = async () => {
    if (!campaign) return;
    const fields = form.getFieldsValue(true);
    const res = [];
    setIsSubmitting(true);

    const request = transformFormFieldsToOverrideRequest(fields);
    const overrideRequest = campaignItemUUIDs.reduce(
      (acc, uuid) => {
        const campaignItem = campaignItems[uuid];
        if (!campaignItem) return acc;
        const diff = diffRequestFromCampaignItem(request, campaignItem);
        if (Object.keys(diff).length) acc[uuid] = diff;
        return acc;
      },
      {} as Record<string, any>
    );
    if (Object.keys(overrideRequest).length) {
      res[0] = await dispatch(
        overrideCampaignItems({ campaignUUID: campaign?.uuid, request: overrideRequest })
      );
    }

    // these are non-override fields and should be sent in the secondary request
    // not parallelizing because there may be a write race condition
    if (type === "modify") {
      const updateRequest = transformFieldsToUpdateRequest({
        fields,
        campaign,
        campaignItems,
        shows: publicShows,
      });
      res[1] = await dispatch(updateCampaignItems(updateRequest));
    }

    setIsSubmitting(false);
    if (res.every((r) => r.status === 200)) {
      onClose();
      dispatch(
        showSuccess(
          `Campaign items have been ${type == "override" ? "overidden" : "modified"} successfully`
        )
      );
    } else {
      res.forEach((r) => {
        if ((r.json as any).validationErrors) {
          dispatch(showError((r.json as any).validationErrors[0]?.errorMessage));
        }
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "dates",
      label: "Dates",
      children: <FlightingDateForm type="campaignItem" isPostSend={type == "modify"} />,
    },
    {
      key: "flighting",
      label: "Flighting",
      children: <FlightingOptionsForm />,
    },
    {
      key: "requirements",
      label: "Requirements",
      children: (
        <>
          <Form.Item
            name="requiresEndorsement"
            valuePropName="checked"
            rules={[{ required: true }]}>
            <Checkbox>Require podcaster to personally endorse this product.</Checkbox>
          </Form.Item>
          <Form.Item name="pixelRequired" valuePropName="checked" rules={[{ required: true }]}>
            <Checkbox>Require measurement pixels for this campaign.</Checkbox>
          </Form.Item>
        </>
      ),
    },
    {
      key: "pricing",
      label: (
        <Tooltip
          title={
            !isEditingOneItem &&
            "Pricing modifications are only available when one item is selected"
          }>
          Pricing
        </Tooltip>
      ),
      disabled: !isEditingOneItem,
      hidden: type === "override",
      children: (
        <ModifyPricingForm
          campaign={campaign}
          campaignItem={isEditingOneItem ? Object.values(campaignItems)?.[0] : undefined}
        />
      ),
    },
  ].filter((item) => !item.hidden);

  const pages = [
    {
      title: (
        <>
          {type === "override" ? "Overriding" : "Modifying"} {campaignItemUUIDs.length}{" "}
          {pluralize(campaignItemUUIDs.length, "Item")}
        </>
      ),
      subtitle: (
        <>
          {isEditingOneItem &&
            Object.values(campaignItems).map((item) => {
              const show = publicShows[item.showUUID];
              return (
                <div className="redcircle-form-label line-clamp-1" key={item.uuid}>
                  {show?.title}
                </div>
              );
            })}
          <span>Specify the modifications you'd like to make for the line items selected.</span>
          {!isEditingOneItem && (
            <p>
              <strong>
                You have more than one item selected, the settings shown are the campaign settings.
              </strong>
            </p>
          )}
          {type === "modify" && (
            <ReforecastAlert campaign={campaign} campaignItems={campaignItems} />
          )}
          {isEditingOneItem && oneItemOverrides && (
            <Alert
              message={
                <>
                  <h5>Current Overrides:</h5>
                  <Divider className="m-txxxs m-bxxxs" />
                  <OverridesText overrides={oneItemOverrides} />
                </>
              }
              type="info"
              className="m-txs"
            />
          )}
        </>
      ),
      noDivider: true,
      body: <Tabs items={items} activeKey={currentTab} onChange={setCurrentTab} />,
      footer: (
        <div className="flex-row-container width-100">
          <Button
            type="primary"
            size="large"
            className="m-la"
            loading={isSubmitting}
            disabled={
              !isEditingOneItem &&
              Object.keys(diffFieldsFromCampaign(fields, campaign)).length === 0
            }
            onClick={() => (isEditingOneItem ? handleSubmit() : setCurrentPage(currentPage + 1))}>
            {isEditingOneItem ? (type === "override" ? "Override" : "Modify") : "Continue"}
          </Button>
        </div>
      ),
    },
    {
      title: type === "override" ? "Review Overrides" : "Review Modifications",
      body: (
        <div>
          <p>Review the following changes before they are saved.</p>
          <ul>
            <li>
              <>
                <strong>
                  {type === "override" ? "Overriding" : "Modifying"} {campaignItemUUIDs.length}{" "}
                  {pluralize(campaignItemUUIDs.length, "item")}
                </strong>
                {campaignItemUUIDs.map((uuid) => {
                  const item = campaignItems[uuid];
                  const show = publicShows[item.showUUID];
                  return (
                    <p className="line-clamp-1 m-lxs m-bxxxs" key={item.uuid}>
                      {show?.title}
                    </p>
                  );
                })}
              </>
            </li>
            <li>
              <>
                <strong>Modifications:</strong>
                {Object.entries(fieldChanges).map(([key, value]) => (
                  <p key={key} className="m-lxs m-bxxxs">
                    <strong>{CAMPAIGN_OVERRIDE_FIELDS[key]}:</strong>{" "}
                    {overrideValueToFriendly(key, value)}
                  </p>
                ))}
              </>
            </li>
            <li>
              <strong>
                These modifications will apply to ad insertions from this point onwards.
              </strong>
            </li>
          </ul>
        </div>
      ),
      footer: (
        <div className="flex-row-container width-100">
          <Button
            type="link"
            size="large"
            className="p-a0"
            onClick={() => setCurrentPage(currentPage - 1)}>
            Back
          </Button>
          <Button
            type="primary"
            size="large"
            className="m-la"
            loading={isSubmitting}
            onClick={handleSubmit}>
            Save Modifications
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Form form={form}>
      <MultiModal open={open} onClose={onClose} pages={pages} currentPage={currentPage} size="sm" />
    </Form>
  );
}

const initializeFormFields = ({
  campaign,
  campaignItems,
  shows,
  type,
}: {
  campaign: ICampaign;
  campaignItems: Record<string, ICampaignItem>;
  shows: PublicShowsReduxState;
  type: "override" | "modify";
}) => {
  const timeline = initializeCampaignTimeline(campaign);
  const deadlines = initializeCampaignDeadlines(campaign);

  // if there are multiple campaign items, we use campaign settings as default
  if (Object.keys(campaignItems).length > 1) {
    return {
      timeline,
      deadlines,
      frequencyConfigs: campaign.frequencyConfigs,
      hardEndDate: campaign.hardEndDate,
      pacing: campaign.pacing,
      pixelRequired: campaign.pixelRequired,
      recentEpisodesOnly: campaign.recentEpisodesOnly,
      requiresEndorsement: campaign.requiresEndorsement,
      targetingOptionsArray:
        campaign.targetingOptions &&
        Object.keys(campaign.targetingOptions).filter(
          (key) => campaign.targetingOptions[key as keyof ICampaignTargetingOptions]
        ),
    };
  }

  // if there is only one campaign item, we use the campaign item settings as default
  const campaignItem = Object.values(campaignItems)[0];
  const fields = {
    timeline:
      campaignItem.lifecycleSettings.startAt.value > 0
        ? [
            dayjs.unix(campaignItem.lifecycleSettings.startAt.value),
            dayjs.unix(campaignItem.lifecycleSettings.endAt.value),
          ]
        : timeline,
    deadlines: {
      assignAudioDeadline:
        campaignItem.lifecycleSettings.assignAudioTask?.deadline.value > 0
          ? dayjs.unix(campaignItem.lifecycleSettings.assignAudioTask?.deadline.value)
          : deadlines.assignAudioDeadline,
      responseDeadline:
        campaignItem.lifecycleSettings.responseTask?.deadline.value > 0
          ? dayjs.unix(campaignItem.lifecycleSettings.responseTask?.deadline.value)
          : deadlines.responseDeadline,
    },
    frequencyConfigs: campaignItem.frequencyConfigs?.value,
    hardEndDate: campaignItem.hardEndDate?.value,
    pacing: campaignItem.pacing?.value,
    pixelRequired: campaignItem.lifecycleSettings.pixelRequired?.value,
    recentEpisodesOnly: campaignItem.recentEpisodesOnly?.value,
    requiresEndorsement: campaignItem.requiresEndorsement?.value,
    targetingOptionsArray:
      campaignItem.targetingOptions &&
      Object.keys(campaignItem.targetingOptions.value).filter(
        (key) => campaignItem.targetingOptions.value[key as keyof ICampaignTargetingOptions]
      ),
    // pricing form fields only for modifications
    ...(type === "modify"
      ? {
          cpm:
            getAverageCPM({
              campaign,
              campaignItem,
              show: shows[campaignItem.showUUID],
              when: "final",
            }) / 100,
          cut: campaignItem.advertisingCutBasisPoints / 100,
          totalBudget: campaignItem.totalBudget,
        }
      : {}),
  };

  return fields;
};

const transformFieldsToUpdateRequest = ({
  fields,
  campaign,
  campaignItems,
  shows,
}: {
  fields: Record<string, any>;
  campaign: ICampaign;
  campaignItems: Record<string, ICampaignItem>;
  shows: PublicShowsReduxState;
}) => {
  const updateRequest = {
    campaign,
    items: Object.values(campaignItems).reduce(
      (acc, item) => {
        acc[item.uuid] = {};
        return acc;
      },
      {} as Record<string, any>
    ),
  };

  if (fields.cpm) {
    const newCPM = fields.cpm * 100;
    Object.entries(updateRequest.items).forEach(([uuid, item]) => {
      const campaignItem = campaignItems[uuid];
      const show = shows[campaignItem.showUUID];
      item.offerRates = {
        enabled: true,
        originalPreRollCPM: show?.advertisementSettings?.hostReadPreRollCPM,
        originalMidRollCPM: show?.advertisementSettings?.hostReadMidRollCPM,
        originalPostRollCPM: show?.advertisementSettings?.hostReadPostRollCPM,
        finalAdjustedPreRollCPM: newCPM,
        finalAdjustedMidRollCPM: newCPM,
        finalAdjustedPostRollCPM: newCPM,
      };
    });
  }

  if (fields.cut) {
    const newCut = fields.cut * 100;
    Object.entries(updateRequest.items).forEach(([uuid, item]) => {
      item.advertisingCutBasisPoints = newCut;
    });
  }

  if (fields.totalBudget) {
    const newTotalBudget = fields.totalBudget;
    Object.entries(updateRequest.items).forEach(([uuid, item]) => {
      item.totalBudget = newTotalBudget;
    });
  }

  return updateRequest;
};
